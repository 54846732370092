import type {
  SingleSelectNodeData,
  SingleSelectOption,
} from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';

type SingleSelectOptionItemProps = {
  data: SingleSelectOption;
  nodeId: string;
};

const SingleSelectOptionItem: React.FC<SingleSelectOptionItemProps> = ({
  data,
  nodeId,
}) => {
  return (
    <SingleSelectOptionItemContainer>
      <Handle
        nodeId={nodeId}
        type="source"
        position={Position.Left}
        id={`${data.id}-left`}
      />
      {data.text || <>&nbsp;</>}
      <Handle
        nodeId={nodeId}
        type="source"
        position={Position.Right}
        id={data.id}
      />
    </SingleSelectOptionItemContainer>
  );
};

const SingleSelectOptionItemContainer = styled.div`
  ${textStyles.body.b12m}
  position: relative;
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

type Props = NodeProps<SingleSelectNodeData>;

const Node: React.FC<Props> = ({ data, selected }) => {
  return (
    <BaseStudioNode
      nodeId={data.id}
      title={'Player choice'}
      subtitle={data.prompt}
      selected={selected}
    >
      <Handle
        nodeId={data.id}
        type="target"
        position={Position.Top}
        id="target-top"
      />

      <SingleSelectNodeContainer>
        {data.options.map((option) => (
          <SingleSelectOptionItem
            nodeId={data.id}
            key={option.id}
            data={option}
          />
        ))}
      </SingleSelectNodeContainer>

      {data.hideSelectedOptions && (
        <LastPathContainer>
          <SingleSelectOptionItem
            nodeId={data.id}
            data={{
              id: 'last-path',
              text: 'Path after other options',
              nextNodeId: undefined as never,
            }}
          />
        </LastPathContainer>
      )}
    </BaseStudioNode>
  );
};

export const SingleSelectNode = memo(Node);

const SingleSelectNodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
`;

const LastPathContainer = styled.div`
  margin-top: ${dimensions.size16};
  padding: ${dimensions.size16} ${dimensions.size16} ${dimensions.size0}
    ${dimensions.size16};
  margin-left: -${dimensions.size16};
  margin-right: -${dimensions.size16};
  border-top: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
`;
