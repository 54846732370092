import { env } from '@maestro/env';

type Params = {
  genre: string;
  tone: string;
  era: string;
  context: string;
};

export const useGenerateWorldTitleAndOverview = () => {
  const generateWorldTitleAndOverview = async (params: Params) => {
    try {
      const response = await fetch(
        `${env.VITE_MAESTRO_API_URL}/world/generate/title-and-overview`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to generate world bible');
      }

      return await response.json();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    generateWorldTitleAndOverview,
  };
};
