import { env } from '@maestro/env';

type Params = {
  count: number;
  itemType: 'character' | 'location';
  story: string;
  worldContext: Record<string, unknown>;
};

export const useGenerateWorldItems = () => {
  const generateWorldItems = async ({
    count,
    itemType,
    story,
    worldContext,
  }: Params) => {
    try {
      const response = await fetch(
        `${env.VITE_MAESTRO_API_URL}/world/generate/items`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            count,
            itemType,
            story,
            worldContext,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to generate world ${itemType}s`);
      }

      const result = await response.json();

      return result;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    generateWorldItems,
  };
};
