import { Button } from '@chakra-ui/react';
import { RpgConfigHudElement, hudIcons } from '@common/studio-types';
import { Icon, ListItem, ListItemColumn } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { ValueBadge } from '../../value/ValueBadgeV2';

type Props = {
  hudElement: RpgConfigHudElement;
  onRemove: () => void;
  onEdit: () => void;
};

export const HudElementRow: React.FC<Props> = (props) => {
  const { hudElement } = props;
  const hudIcon = hudIcons.find((icon) => icon.ref === hudElement.icon);

  return (
    <>
      <ListItem onClick={props.onEdit} draggable record={hudElement}>
        <ListItemColumn width="30%">
          {hudIcon && <HudIcon src={hudIcon.icon} />}
          {hudElement.displayName || (
            <StyledValueBadge value={hudElement.value} hideIcon />
          )}
        </ListItemColumn>
        <ListItemColumn flex>
          <StyledValueBadge value={hudElement.value} />
        </ListItemColumn>
        <ListItemColumn reverse width={dimensions.size120}>
          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
          >
            <Icon name="edit" size={rawDimensions.size20} />
          </Button>

          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove();
            }}
          >
            <Icon name="trash" size={rawDimensions.size20} />
          </Button>
        </ListItemColumn>
      </ListItem>
    </>
  );
};

const StyledValueBadge = styled(ValueBadge)`
  ${textStyles.body.b14m}
  background: transparent;
  gap: ${dimensions.size12};
  padding: ${dimensions.size0};
`;

const HudIcon = styled.img`
  width: ${dimensions.size16};
  height: ${dimensions.size16};
`;
