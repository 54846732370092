import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Edge, useReactFlow } from 'reactflow';

type Args = {
  nodeId: string;
  /**
   * Number of nodes to traverse back to get the context
   */
  traverseNodesCount: number;
};

export const useGetAiContext = () => {
  const reactFlow = useReactFlow();

  return (args: Args): string => {
    const { nodeId } = args;
    const sourceByTarget: Record<string, StudioNodeData> = {};
    const sourceHandleByTarget: Record<string, string> = {};
    const map: Record<string, StudioNodeData> = {};
    const count = { value: 0 };
    const nodes = reactFlow.getNodes();
    const edges = reactFlow.getEdges();

    nodes.forEach((node) => (map[node.id] = node.data));

    edges.forEach((edge: Edge) => {
      if (edge.sourceHandle?.startsWith('studio-')) {
        sourceHandleByTarget[edge.target] = edge.sourceHandle;
      }

      if (!sourceByTarget[edge.target]) {
        sourceByTarget[edge.target] = map[edge.source];
      }
    });

    let node = map[nodeId];
    const prompt: string[] = [];
    const visited = new Set<string>();

    do {
      const optionId = sourceHandleByTarget[node.id];

      node = sourceByTarget[node.id];

      if (node) {
        if (visited.has(node.id)) {
          break;
        }

        visited.add(node.id);

        if (node.type === StudioNodeType.NarratorText) {
          prompt.push(`### Narrator Text\n\`\`\`\n${node.text}\n\`\`\``);
          count.value++;
        } else if (node.type === StudioNodeType.SingleSelect && optionId) {
          const selectedOption = node.options.find(({ id }) =>
            optionId.includes(id),
          );

          prompt.push(
            `### Player choice\n\`\`\`\n${selectedOption!.text}\n\`\`\``,
          );

          count.value++;
        }
      }
    } while (node && count.value < args.traverseNodesCount);

    return prompt.reverse().join('\n\n');
  };
};
