import {
  CloseButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import React, { forwardRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

type Props = InputProps & {
  leftIcon?: React.ReactNode;
  rightElement?: React.ReactNode;
  hint?: string;
  showRemoveButton?: boolean;
  onRemove?: (evt: React.MouseEvent) => void;
  maxCharacters?: number;
  enforceMaxCharacters?: boolean;
  props?: InputGroupProps;
};

export const TextInput: React.FC<Props> = forwardRef(
  (props, ref?: React.ForwardedRef<never>) => {
    const theme = useTheme();
    const {
      onRemove,
      maxCharacters,
      enforceMaxCharacters,
      showRemoveButton,
      rightElement,
      leftIcon,
      hint,
      ...rest
    } = props;
    const count = (props.value as string)?.length || 0;
    const [isFocused, setIsFocused] = useState(false);
    const isWarning = !!maxCharacters && count > maxCharacters;
    const removeButtonWidth = 48;
    const maxCharactersWidth = 42;
    const size =
      (showRemoveButton ? removeButtonWidth : 0) +
      (maxCharacters ? maxCharactersWidth : 0);

    const style =
      props.size === 'lg'
        ? { height: '22px', fontSize: '18px', lineHeight: '22px' }
        : { height: '18px', fontSize: dimensions.size14, lineHeight: '18px' };

    return (
      <Container>
        <InputGroup
          display={'flex'}
          flexFlow={'row'}
          alignItems={'center'}
          gap={dimensions.size16}
          background={theme.colors.background.shade}
          border="1px solid"
          borderColor={
            isFocused
              ? theme.colors.border.accent[1000]
              : props.isInvalid
                ? theme.colors.border.error[1000]
                : 'transparent'
          }
          padding={`${dimensions.size14} ${dimensions.size12}`}
          borderRadius={dimensions.size4}
          {...(props.props ?? {})}
        >
          {leftIcon}
          <Input
            {...rest}
            ref={ref}
            maxLength={
              maxCharacters && enforceMaxCharacters ? maxCharacters : undefined
            }
            variant={isWarning ? 'warning' : undefined}
            onBlur={(evt) => {
              props?.onBlur?.(evt);
              setIsFocused(false);
            }}
            onFocus={() => setIsFocused(true)}
            background={'none'}
            _dark={{
              background: 'none',
              border: 'none',
              padding: 'unset',
              fontWeight: 500,
              focusBorderColor: 'transparent',
              ...style,
              _invalid: {
                borderColor: 'transparent',
                boxShadow: 'none',
                '&:parent': {
                  borderColor: 'red',
                },
              },
            }}
            border={'none'}
            padding={'unset'}
            height={dimensions.size20}
          />
          {(!!maxCharacters || showRemoveButton) && (
            <InputRightElement
              flex={1}
              maxWidth={`${size}px`}
              width={'fit-content'}
              position="unset"
              height={dimensions.size20}
              fontSize={dimensions.size14}
              fontWeight={500}
              lineHeight={dimensions.size20}
            >
              {!!maxCharacters && (
                <CharacterCount
                  $isWarning={isWarning}
                  $isFocused={isFocused}
                  $isInvalid={props.isInvalid}
                >
                  {count}/{maxCharacters}
                </CharacterCount>
              )}
              {showRemoveButton && (
                <CloseButton
                  marginLeft={dimensions.size4}
                  variant="icon"
                  color={'text.placeholder'}
                  onClick={onRemove}
                />
              )}
            </InputRightElement>
          )}
          {rightElement}
        </InputGroup>
        {hint && <Hint $isFocused={isFocused}>{hint}</Hint>}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  flex: 1;
`;

const Hint = styled.div<{ $isFocused?: boolean }>`
  ${textStyles.body.b12m}
  color: ${({ theme, $isFocused }) =>
    $isFocused ? theme.colors.text.accent : theme.colors.text.body};
`;

const CharacterCount = styled.div<{
  $isWarning: boolean;
  $isFocused: boolean;
  $isInvalid?: boolean;
}>`
  ${textStyles.label.lb10sb}
  display: flex;
  justify-content: flex-end;
  color: ${({ $isWarning, $isFocused, $isInvalid, theme }) =>
    $isWarning
      ? theme.colors.warning
      : $isFocused
        ? theme.colors.text.accent
        : $isInvalid
          ? theme.colors.border.error[1000]
          : theme.colors.text.placeholder};
  transition: color 0.2s;
`;
