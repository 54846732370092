import { useDisclosure } from '@chakra-ui/react';
import { SelectInput } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';
import { Item } from '../../hooks/useItems';
import { useStudioFlowStore } from '../../hooks/useStudioFlowStore';
import { CreateItemModal } from '../items/CreateItemModal';

type Props = {
  value: string;
  items: Item[];
  onItemCreated: (item: Item) => void;
  onChange: (item: Item) => void;
};

export const ItemInput: React.FC<Props> = (props) => {
  const { onChange, items, value, onItemCreated } = props;
  const { seriesId } = useStudioFlowStore();
  const item = items.find((item) => item.id === value);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onAddItem = () => {
    onOpen();
  };

  const onCreateItem = (item: Item) => {
    onItemCreated(item);
    onChange(item);
    onClose();
  };

  const itemRenderer = (item: Item) => (
    <IconContainer>
      <img src={item.imagePath} />
      {item.name}
    </IconContainer>
  );

  return (
    <>
      {seriesId && (
        <CreateItemModal
          seriesId={seriesId}
          isOpen={isOpen}
          onClose={onClose}
          onCreated={onCreateItem}
        />
      )}
      <Container>
        <SelectInput
          onCreateItemClick={onAddItem}
          createItemLabel="Create item"
          getId={(item) => item.id}
          groups={[{ name: 'Items', items: items.map(({ id }) => id) }]}
          listItems={items}
          onChange={onChange}
          onSearch={(search) =>
            items.filter((item) =>
              item.name.toLowerCase().includes(search.toLowerCase()),
            )
          }
          renderListItem={itemRenderer}
          renderSelectedItem={itemRenderer}
          selectedItem={item}
        />
        {!item && <ErrorMessage>You must select an item</ErrorMessage>}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  flex: 1;
`;

const ErrorMessage = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.base.red[800]};
`;

const IconContainer = styled.div`
  ${textStyles.body.b12m}
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  border-radius: ${dimensions.size4};

  img {
    width: ${dimensions.size32};
    height: ${dimensions.size32};
    object-fit: cover;
    border-radius: ${dimensions.size4};
  }
`;
